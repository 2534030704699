<template>
    <div class="layout">
        <Header />
        <div class="container">
            <router-view :key="$route.fullPath"></router-view>
        </div>
        <Footer3 />
    </div>
</template>

<script>
import Header from './header.vue';
import Footer from './footer.vue';
import Footer3 from './footer3.vue';
export default {
    components: {
        Header,
        Footer,
        Footer3,
    },
};
</script>

<style lang="scss" scoped>
.layout {
    height: 100%;
    background-color: #f1f3f6;
    .container {
        height: 100%;
    }
}
</style>
