import request from '../index.js';
// 管理员登陆
export function httpPostAdminLogin(params) {
    return request.post('/api/v1/signIn', params);
}
//退出登录
export function httpLogout() {
    return request.delete(`/api/v1/signOut`);
}

// 管理员创建课程
export function httpPostAdminCourse(params) {
    return request.post('/api/v1/admin/course', params);
}
// 管理员删除课程
export function httpDeleteAdminCourse(courseId) {
    return request.delete(`/api/v1/admin/course/${courseId}`);
}
//管理员编辑课程
export function httpPutUpdateCourse(params) {
    return request.put2(`/api/v1/admin/updateCourse`, params);
}
// 管理员新增课程集、并且获取上传凭证
export function httpPostAdminCourseEpisode(params) {
    return request.post(`/api/v1/video/videoUpload`, params);
}
//更新上传状态
export function httpUpdateVideoStatus(episodeId) {
    return request.get(`/api/v1/video/${episodeId}/videoStatus`);
}
// 管理员删除课程集
export function httpDeleteAdminCourseEpisode(episodeId) {
    // return request.delete(`/api/v1/admin/course/${courseId}/episode`);
    return request.delete(`/api/v1/admin/course/episode/${episodeId}`);
}
// 管理员更新课程集
export function httpPatchAdminCourseEpisode(episodeId, params) {
    // return request.patch(`/api/v1/admin/course/${courseId}/episode`, params);
    return request.patch(`/api/v1/admin/course/episode/${episodeId}`, params);
}
// 管理员更新课程集序号
export function httpPatchAdminCourseEpisodeOrder(courseId, params) {
    return request.patch(`/api/v1/admin/course/${courseId}/episodes/orders`, params);
}

// 管理员新增或更新考试
export function httpPutAdminExam(courseId, params) {
    return request.put2(`/api/v1/admin/course/${courseId}/episodes/orders`, params);
}

// 管理员修改密码
export function httpPatchAdminResetPwd(params) {
    return request.put2('/api/v1/admin/current/password', params);
}
// 管理员上传考试链接
export function httpPutAddExam(params) {
    let { educateeId, episodeId, url } = params;
    return request.put2(`/api/v1/admin/educatee/${educateeId}/course/episode/${episodeId}/exam`, {
        url,
    });
}
//获取考试链接
export function httpGetExamInfo(educateeId, episodeId) {
    return request.get(`/api/v1/admin/educatee/${educateeId}/course/episode/${episodeId}/getInfo`);
}
