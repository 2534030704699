<template>
    <div class="home-footer">
        <div class="home-footer_1">
            <div class="home-footer_1_wrap">
                <ul>
                    <li class="link">
                        <a href="https://www.spsspro.com/help/" target="_blank">帮助中心</a>
                    </li>
                    <li>｜</li>
                    <li class="link">
                        <a href="/terms/" target="_blank">服务协议</a>
                    </li>
                    <li>｜</li>
                    <li class="link">
                        <a href="/privacy/" target="_blank">隐私政策</a>
                    </li>
                    <li>｜</li>
                    <li class="link">
                        <a href="https://www.spsspro.com/about/" target="_blank">关于我们</a>
                    </li>
                    <li>｜</li>
                    <li class="link">
                        <a href="https://bbs.spsspro.com/" target="_blank" @click="setGio('tail')"
                            >SPSSPRO社区</a
                        >
                    </li>
                </ul>
                <div v-if="isMobile" class="concat-info-m mt8 mb24">
                    电话：400 670 0778<br />
                    邮箱：spsspro@idiaoyan.com<br />
                    地址：上海市徐汇区宜山路700号B2幢楼22楼
                </div>
                <div v-else class="concat-info">
                    电话：400 670
                    0778｜邮箱：spsspro@idiaoyan.com｜地址：上海市徐汇区宜山路700号B2幢楼22楼｜微信：
                    <div class="concat-qrcode">
                        <div class="concat-qrcode-hover">
                            <wj-image
                                :src="require('@/assets/images/home-footer-big-qrcode-pl.png')"
                                alt="微信号"
                                style="width: 72px; height: 72px; margin-top: 8px"
                            >
                            </wj-image>
                        </div>
                    </div>
                </div>
            </div>
            <div class="concat-qrcode-2 concat-qrcode">
                <div v-if="isMobile" class="qrcode-item">
                    <span>微信联系</span>
                    <wj-image
                        :src="require('@/assets/images/home-footer-big-qrcode-pl.png')"
                        style="width: 72px; height: 72px"
                        alt="SPSSPRO微信联系公众号"
                    >
                    </wj-image>
                </div>
                <div class="qrcode-item">
                    <span>微信公众号</span>
                    <wj-image
                        :src="require('@/assets/images/home-qr-code.png')"
                        style="width: 72px; height: 72px"
                        alt="SPSSPRO微信公众号二维码"
                    >
                    </wj-image>
                </div>
                <div class="qrcode-item">
                    <span>社区移动端</span>
                    <wj-image
                        :src="require('@/assets/images/bbs-prod-1.png')"
                        style="width: 72px; height: 72px"
                        alt="社区移动端二维码"
                    >
                    </wj-image>
                </div>
            </div>
        </div>
        <div class="home-footer_2">
            <div style="margin-top: 20px" class="footer-text-1">
                ©2013-2023 众言科技股份有限公司
                <a
                    class="footer-text-2"
                    href="https://beian.miit.gov.cn"
                    target="_blank"
                    rel="noopener nofollow"
                >
                    苏ICP备13021334号-22</a
                >
            </div>
            <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002003723"
                class="record-info"
                rel="noopener nofollow"
            >
                <wj-image
                    style="width: 17px; height: 17px"
                    :src="require('@/assets/images/record.png')"
                ></wj-image
                >苏公网安备 32059002003723号
            </a>
        </div>
    </div>
</template>
<script>
import { Image } from '@wenjuan/ui';
export default {
    components: {
        'wj-image': Image,
    },
    data() {
        return {
            isMobile: false,
        };
    },
    created() {
        if (
            navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )
        ) {
            this.isMobile = true;
        }
    },
};
</script>
<style scoped lang="scss">
.home-footer {
    height: 202px;
    margin-top: 40px;
    @media (max-width: 640px) {
        padding: 24px 24px 10px;
        height: auto;
    }
    .home-footer_1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1170px;
        margin: 0 auto;
        height: 132px;
        font-size: 14px;
        color: #6a6f77;
        width: 960px;
        @media (max-width: 640px) {
            width: 100%;
            height: auto;
            flex-direction: column;
        }
        .home-footer_1_wrap {
            width: 100%;
            font-size: 14px;
        }
        .concat-qrcode-2 {
            float: right;
            display: flex;
            .qrcode-item {
                font-size: 14px;
                display: flex;
                flex-direction: column;
                align-items: center;
                @media (max-width: 640px) {
                    flex-direction: column-reverse;
                }
                &:first-child {
                    margin-right: 15px;
                }
                @media (max-width: 640px) {
                    margin-right: 28px;
                    &:first-child {
                        margin-right: 28px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        .concat-info {
            display: flex;
            align-items: center;
            .concat-qrcode {
                position: relative;
                margin-top: 2px;
                width: 14px;
                height: 14px;
                background: url('~@/assets/images/man-qr-code.png') no-repeat;
                background-size: 100%;
                cursor: pointer;
                .concat-qrcode-hover {
                    display: none;
                    position: absolute;
                    width: 88px;
                    height: 88px;
                    text-align: center;
                    background-color: #fff;
                    left: 15px;
                    top: 0;
                    box-shadow: 2px 2px 8px 0px #f0f3f7;
                    z-index: 10;
                    img {
                        margin-top: 8px;
                        z-index: 10;
                    }
                }
                &:hover {
                    background: url('~@/assets/images/man-qr-code-hover.png') no-repeat;
                    background-size: 100%;
                    .concat-qrcode-hover {
                        display: block;
                    }
                }
            }
        }
        .concat-info-m {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            font-weight: normal;
            line-height: 28px;
            letter-spacing: 0px;
            color: #6a6f77;
        }
        ul {
            display: flex;
            align-items: center;
            padding-left: 0;
            margin-top: 0;
            flex-wrap: wrap;
            li {
                list-style: none;
            }
            .link {
                cursor: pointer;
                a {
                    color: #6a6f77;
                    font-weight: 400;
                    &:hover {
                        color: #1a78ff;
                    }
                }
            }
        }
        // width 310px
        div {
            text-align: left;
        }
    }
    .home-footer_2 {
        border-top: 1px solid #eef1f5;
        height: 69px;
        font-size: 12px;
        color: #aaadb1;
        display: flex;
        flex-direction: column;
        align-items: center;
        // padding-top 20px
        // justify-content center
        padding-bottom: env(safe-area-inset-bottom);
        line-height: 12px;
        @media (max-width: 640px) {
            margin-top: 4px;
            height: auto;
        }
        .record-info {
            display: flex;
            align-items: center;
            text-decoration: none;

            img {
                margin-right: 5px;
            }
        }
        a {
            color: #aaadb1;
        }
        .footer-text-1 {
            @media (max-width: 640px) {
                text-align: center;
                line-height: 17px;
            }
            .footer-text-2 {
                @media (max-width: 640px) {
                    display: inline-block;
                }
            }
        }
    }
}
</style>
